import defaultTheme from "./defaultTheme";
import { merge, cloneDeep } from "lodash";

const COLORS = {
  primary: "#003C68",
  secondary: "#93328E",
  tertiary: "#005eb8",
  focus: "#f9b000",
  focusLight: "#e5eef7",
  white: "#FFFFFF",
  black: "#000000",
  disabled: "#f1f1f1",
  success: "#329b83",
  danger: "#AA112C"
};

const varmlandTheme = {
  fontFamily: "Open Sans",
  colors: COLORS,
  button: {
    primary: {
      color: COLORS.primary,
      hover: "#003053",
    },
    secondary: {
      hover: COLORS.focusLight,
    },
    copy: {
      backgroundColor: COLORS.white,
      borderColor: COLORS.primary,
      hover: {
        backgroundColor: COLORS.focusLight,
      },
    },
    success: {
      color: COLORS.success,
      hover: "#008264",
    },
    danger: {
      color: COLORS.danger,
      hover: "#880e23",
    },
  },
  horizontalLine: {
    color: COLORS.primary,
  },
  icon: {
    fill: COLORS.primary,
  },
  input: {
    backgroundColor: COLORS.focusLight,
    color: COLORS.primary,
    dangerColor: "#ff8fa0",
    border: {
      color: "#99b1c2",
    },
    focus: {
      backgroundColor: COLORS.focusLight,
      border: {
        color: COLORS.primary,
        boxShadow: "0 0 0 0.2rem rgba(170, 43, 35,.25)",
      },
    },
    dropDown: {
      backgroundColor: COLORS.focusLight,
      listBackgroundColor: COLORS.white,
      color: COLORS.primary,
      border: {
        width: "1px",
        color: COLORS.primary,
      },
      hover: {
        color: COLORS.primary,
        backgroundColor: COLORS.focusLight,
        border: COLORS.primary,
      },
    },
  },
  navbar: {
    color: COLORS.white,
    backgroundColor: COLORS.primary,
    border: {
      color: COLORS.primary,
      width: "3px",
    },
    logo: {
      height: "45px",
      marginTop: "-15px",
      marginBottom: "-10px"
    },
    link: {
      hover: {
        color: COLORS.primary,
        backgroundColor: COLORS.focusLight,
      },
    },
  },
  typography: {
    headerPrimaryColor: COLORS.primary,
    fontSize: "14px",
    font: "Open Sans",
    boldFont: "Open Sans",
    headingFont: "Helvetica Neue",
    headingFontWeight: "normal",
    linkColor: COLORS.primary,
  },
  checkbox: {
    border: {
      color: COLORS.primary,
    },
    check: {
      border: {
        color: COLORS.white,
      },
    },
    hover: {
      border: {
        color: COLORS.primary,
      },
    },
  },
  radioButton: {
    border: {
      color: COLORS.primary,
    },
    check: {
      border: {
        color: COLORS.white,
      },
    },
    hover: {
      border: {
        color: COLORS.primary,
      },
    },
  },
  spinner: {
    color: COLORS.primary,
  },
  feedback: {
    color: COLORS.white,
  },
};

export default merge(cloneDeep(defaultTheme), varmlandTheme);
