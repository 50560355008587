import PropTypes from "prop-types";
import React, { Component } from "react";
import UnexpectedErrorView from "../views/UnexpectedErrorView";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // https://reactjs.org/docs/react-component.html#static-getderivedstatefromerror
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // https://reactjs.org/docs/react-component.html#componentdidcatch
    this.props.errorLogger
      ? this.props.errorLogger(error, errorInfo)
      : console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return this.props.ErrorComponent;
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  errorLogger: PropTypes.func,
  ErrorComponent: PropTypes.element,
};

ErrorBoundary.defaultProps = {
  ErrorComponent: <UnexpectedErrorView />,
};

export default ErrorBoundary;
