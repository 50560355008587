import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { H1 } from "../components";
import Spinner from "../components/Spinner";

const LoadingViewWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
`;

const LoadingView = ({ message }) => (
  <LoadingViewWrapper>
    <H1>{message}</H1>
    <Spinner />
  </LoadingViewWrapper>
);

LoadingView.propTypes = {
  message: PropTypes.string,
};

export default LoadingView;
