import defaultTheme from "./defaultTheme";
import { merge, cloneDeep } from "lodash";

const COLORS = {
  primary: "#aa2b23",
  secondary: "#f7e4e4",
  tertiary: "#777777",
  danger: "#aa2b23",
  focus: "#00ADA8",
  white: "#FFFFFF",
  black: "#000000",
  disabled: "#f1f1f1",
  success: "#00ada8",
  successLight: "#ccefee"
};

const achimaTheme = {
  fontFamily: "Lato",
  colors: COLORS,
  button: {
    primary: {
      color: COLORS.primary,
      hover: "#880000",
    },
    secondary: {
      hover: COLORS.secondary,
    },
    copy: {
      backgroundColor: COLORS.white,
      borderColor: COLORS.primary,
      hover: {
        backgroundColor: COLORS.secondary,
      },
    },

    success: {
      color: COLORS.success,
      hover: "#00a6a1",
    },
  },
  horizontalLine: {
    color: COLORS.primary,
  },
  icon: {
    fill: COLORS.primary,
  },
  input: {
    backgroundColor: COLORS.successLight,
    color: COLORS.black,
    dangerColor: "#aa2b23",
    border: {
      color: '#99dedc',
    },
    focus: {
      backgroundColor: COLORS.successLight,
      color: COLORS.black,
      border: {
        color: COLORS.success,
        boxShadow: "0 0 0 0.2rem rgba(170, 43, 35,.25)",
      },
    },
    dropDown: {
      backgroundColor: COLORS.secondary,
      listBackgroundColor: COLORS.white,
      color: COLORS.primary,
      border: {
        width: "1px",
        color: COLORS.primary,
      },
      hover: {
        color: COLORS.primary,
        backgroundColor: COLORS.secondary,
        border: COLORS.primary,
      },
    },
  },
  navbar: {
    color: COLORS.white,
    backgroundColor: COLORS.primary,
    border: {
      color: COLORS.primary,
    },
    logo: {
      height: "24px",
      marginTop: "-4px",
    },
    link: {
      hover: {
        color: COLORS.primary,
        backgroundColor: COLORS.secondary,
      },
    },
  },
  typography: {
    headerPrimaryColor: COLORS.primary,
    font: "Lato",
    boldFont: "Lato Bold",
    headingFont: "Lato Bold",
    linkColor: COLORS.primary,
  },
  checkbox: {
    border: {
      color: COLORS.primary,
    },
    check: {
      border: {
        color: COLORS.white,
      },
    },
    hover: {
      border: {
        color: COLORS.primary,
      },
    },
  },
  radioButton: {
    border: {
      color: COLORS.primary,
    },
    check: {
      border: {
        color: COLORS.white,
      },
    },
    hover: {
      border: {
        color: COLORS.primary,
      },
    },
  },
  spinner: {
    color: COLORS.primary,
  },
  feedback: {
    color: COLORS.white,
  },
};

export default merge(cloneDeep(defaultTheme), achimaTheme);
