import styled from "styled-components";
import { InputLabel } from "../Typography";

export const Input = styled.input`
  font-size: 16px;
  color: ${({ theme }) => theme.input.color};
  background-color: ${({ theme }) => theme.input.backgroundColor};
  border-width: ${({ theme }) => theme.input.border.width};
  border-style: solid;
  border-color: ${({ theme }) => theme.input.border.color};
  border-radius: ${({ theme }) => theme.input.border.radius};
  width: 100%;
  padding: 0.375rem 0.75rem;
  
  &:focus,
  focus-visible {
    color: ${({ theme }) => theme.input.focus.color};
    background-color: ${({ theme }) => theme.input.focus.backgroundColor};
    border-color: ${({ theme }) => theme.input.focus.border.color};
    box-shadow: none;
    outline: none;
  }
  &:disabled {
    color: ${({ theme }) => theme.input.disabledColor}
    background-color: ${({ theme }) => theme.input.disabledColorSupplement};
    border-color: ${({ theme }) =>
      theme.input.disabledColorSupplement}; // TODO: What color?
  }
  &:hover {
    box-shadow: none;
    cursor: cursor;
  }
`;

export const ValidationError = styled.span`
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.input.dangerColor};
`;
