import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const StyledCheckBox = styled.span`
  &::before {
    position: absolute;
    content: "";
    top: 9px;
    left: 0;
    height: 14px;
    width: 14px;
    border: ${({ theme }) => theme.checkbox.border.color} 0.5px solid;
  }

  &::after,
  :checked {
    position: absolute;
    top: 10px;
    left: 0;
    height: 8px;
    width: 14px;
    border-left: ${({ theme }) => theme.checkbox.check.border.color} 3px solid;
    border-bottom: ${({ theme }) => theme.checkbox.check.border.color} 3px solid;
    transform: rotate(-52deg);
  }
`;

const HiddenCheckBoxInput = styled.input`
  position: absolute;
  opacity: 0;

  &:checked + ${StyledCheckBox}::before {
    background: ${({ theme }) => theme.checkbox.border.color};
    border: ${({ theme }) => theme.checkbox.border.color} 1px solid;
  }

  &:disabled + ${StyledCheckBox}::before {
    opacity: 0.5;
    filter: grayscale(0.5);
    cursor: context-menu;
  }

  &:disabled + ${StyledCheckBox}::after {
    opacity: 0.5;
    filter: grayscale(0.5);
    cursor: context-menu;
  }

  &:checked + ${StyledCheckBox}::after {
    content: "";
  }

  &:not(:checked):not(:disabled):hover + ${StyledCheckBox}::before {
    box-shadow: 0 0 0 0.75px ${({ theme }) =>
      theme.checkbox.hover.border.color} inset;
    //border-color: ${({ theme }) => theme.checkbox.hover.border.color};
  }
`;

export const Label = styled.label`
  font-size: 16px;
  display: block;
  position: relative;
  padding-top: 4px;
  padding-left: 20px;
  cursor: pointer;
  user-select: none;
  
  &[data-disabled="true"] {
    opacity: 0.5;
    filter: grayscale(0.5);
    cursor: default;
  }
`;

export const CheckboxWithLabel = ({ label, handleChange, ...props }) => {
  return (
    <Label data-disabled={props.disabled}>
      {label}
      <HiddenCheckBoxInput type="checkbox" onChange={handleChange} {...props} />
      <StyledCheckBox />
    </Label>
  );
};

CheckboxWithLabel.propTypes = {
  label: PropTypes.string,
  handleChange: PropTypes.func,
  ...HiddenCheckBoxInput.propTypes,
};

Label.propTypes = {
  children: PropTypes.node,
};
