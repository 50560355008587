import defaultTheme from "./defaultTheme";
import { merge, cloneDeep } from "lodash";

const COLORS = {
  primary: "#0d3050",
  secondary: "#d1dff1",
  tertiary: "#f0f4fa",
  focus: "#fcd180",
  focusLight: "#fde1ab",
  white: "#FFFFFF",
  black: "#000000",
  disabled: "#f1f1f1",
  success: "#4FD142",
  danger: "#b32941",
};

const capioTheme = {
  fontFamily: "proximanova-regular, sans-serif",
  colors: COLORS,
  button: {
    primary: {
      color: COLORS.primary,
      hover: "#1C5DAC",
    },
    secondary: {
      hover: COLORS.tertiary,
    },
    copy: {
      backgroundColor: COLORS.white,
      borderColor: COLORS.primary,
      hover: {
        backgroundColor: COLORS.tertiary,
      },
    },
    success: {
      color: COLORS.success,
      hover: "#4cc740",
    },
    danger: {
      color: COLORS.danger,
      hover: "#AA112C",
    },
  },
  horizontalLine: {
    color: COLORS.primary,
  },
  icon: {
    fill: COLORS.primary,
  },
  input: {
    backgroundColor: COLORS.white,
    color: COLORS.primary,
    dangerColor: "#b32941",
    border: {
      color: COLORS.primary,
    },
    focus: {
      backgroundColor: COLORS.tertiary,
      border: {
        color: COLORS.primary,
        boxShadow: "0 0 0 0.2rem rgba(170, 43, 35,.25)",
      },
    },
    dropDown: {
      backgroundColor: COLORS.tertiary,
      listBackgroundColor: COLORS.white,
      color: COLORS.primary,
      border: {
        width: "1px",
        color: COLORS.primary,
      },
      hover: {
        color: COLORS.primary,
        backgroundColor: COLORS.secondary,
        border: COLORS.primary,
      },
    },
  },
  navbar: {
    color: COLORS.white,
    backgroundColor: COLORS.primary,
    border: {
      color: COLORS.primary,
      width: "3px",
    },
    logo: {
      height: "35px",
      marginTop: "-12px",
      marginBottom: "-10px",
    },
    link: {
      hover: {
        color: COLORS.primary,
        backgroundColor: COLORS.tertiary,
      },
    },
  },
  typography: {
    headerPrimaryColor: COLORS.primary,
    fontSize: "16px",
    font: "proximanova-regular, sans-serif",
    boldFont: "proximanova-bold, sans-serif",
    headingFont: "proximanova-bold, sans-serif",
    headingFontWeight: "bold",
    linkColor: COLORS.primary,
  },
  checkbox: {
    border: {
      color: COLORS.primary,
    },
    check: {
      border: {
        color: COLORS.white,
      },
    },
    hover: {
      border: {
        color: COLORS.primary,
      },
    },
  },
  radioButton: {
    border: {
      color: COLORS.primary,
    },
    check: {
      border: {
        color: COLORS.white,
      },
    },
    hover: {
      border: {
        color: COLORS.primary,
      },
    },
  },
  spinner: {
    color: COLORS.primary,
  },
  feedback: {
    color: COLORS.white,
  },
  table: {
    row: {
      odd: {
        backgroundColor: COLORS.tertiary,
        color: COLORS.primary,
      },
      even: {
        backgroundColor: COLORS.white,
        color: COLORS.primary,
      },
      hover: {
        backgroundColor: COLORS.secondary,
        color: COLORS.primary,
      },
    },
  },
};

export default merge(cloneDeep(defaultTheme), capioTheme);
