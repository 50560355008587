import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { A, H2, H4, H5, P, PrimaryButton } from "../components";
import HorizontalLine from "../components/HorizontalLine";

const Icon = styled.svg`
  fill: ${({ theme }) => theme.icon.fill};
`;

const IconWrapper = styled.div`
  margin-top: 12px;
`;

const UnexpectedErrorView = () => {
  return (
    <Container>
      <Row>
        <Col>
          <H2>Oväntat fel</H2>
          <br className="d-none d-sm-block" />
        </Col>
      </Row>
      <Row>
        <Col sm={9} className="col-sm-offset-1">
          <H5 className="d-none d-sm-block">Ett okänt fel har uppstått!</H5>
          <br />
          <P>
            Programmet har tyvärr stött på ett okänt fel. <br />
            Klicka på knappen nedan för att komma tillbaka till startsidan.
          </P>
          <PrimaryButton onClick={() => window.history.push("/")}>
            Startsidan
          </PrimaryButton>
          <br />
        </Col>
        <Col sm={2} className="d-none d-sm-block">
          <IconWrapper>
            <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 79.4 72.91">
              <title>CollaboDoc-Pictogram-Laptop-Sad</title>
              <circle className="cls-1" cx="52.13" cy="17.65" r="3" />
              <circle className="cls-1" cx="26.58" cy="17.65" r="3" />
              <path
                className="cls-1"
                d="M7290.77,1756.8a2.77,2.77,0,0,0,3.7-1.22c1.1-2.17,4.19-4.5,9.57-4.5,6.45,0,8.86,3.19,9.59,4.56a2.75,2.75,0,1,0,4.85-2.59c-1.2-2.25-5-7.47-14.44-7.47-7.88,0-12.64,3.89-14.48,7.52A2.76,2.76,0,0,0,7290.77,1756.8Z"
                transform="translate(-7264.68 -1720.57)"
              />
              <path
                className="cls-1"
                d="M7343.75,1792a2.26,2.26,0,0,0,.11-.22c0-.09.06-.19.09-.28s.05-.16.07-.24,0-.2,0-.3a1.79,1.79,0,0,0,0-.24h0v0l-.1-8.12a2.73,2.73,0,0,0-.76-1.86l-11.89-12.49,0,0a1.59,1.59,0,0,0-.24-.2,1.22,1.22,0,0,0-.17-.15l-.26-.14a1.39,1.39,0,0,0-.21-.11,1.84,1.84,0,0,0-.25-.09l-.26-.08-.22,0a1.66,1.66,0,0,0-.32,0h-38.7l-.16,0a2.07,2.07,0,0,0-.35,0l-.22.06-.29.09-.22.11a1.65,1.65,0,0,0-.26.14,1.39,1.39,0,0,0-.19.15,1.92,1.92,0,0,0-.22.18,1.43,1.43,0,0,0-.2.23.93.93,0,0,0-.13.15l-6.67,9.37a2.75,2.75,0,0,0,2.24,4.34h37a2.75,2.75,0,0,0,0-5.5h-31.67l2.76-3.87h36.07l10.33,10.86.06,4.25h-68.36v-4.51l11.11-15.11s0,0,0,0,.11-.18.16-.27a2.12,2.12,0,0,0,.11-.21,2.5,2.5,0,0,0,.09-.25c0-.09.06-.18.08-.26a2.08,2.08,0,0,0,0-.23,1.73,1.73,0,0,0,0-.32.43.43,0,0,0,0,0v-40.66h44.41v33.52a2.75,2.75,0,1,0,5.5,0v-36.27a2.75,2.75,0,0,0-2.75-2.75h-49.91a2.75,2.75,0,0,0-2.75,2.75v42.51l-11.12,15.11a2.72,2.72,0,0,0-.53,1.63v8.16a2.75,2.75,0,0,0,2.75,2.75h73.93l.27,0,.27,0,.27-.08.24-.08a2,2,0,0,0,.25-.14,2.39,2.39,0,0,0,.22-.12l.21-.18.19-.15c.07-.07.12-.15.18-.22s.11-.12.15-.19S7343.7,1792.09,7343.75,1792Z"
                transform="translate(-7264.68 -1720.57)"
              />
            </Icon>
          </IconWrapper>
        </Col>
      </Row>
      <br />
      <HorizontalLine />
      <H4>Support</H4>
      <P>
        För ytterligare information angående felet får du gärna kontakta vår
        support via telefon på{" "}
        <A href="tel:0046812826600">+ 46 (0) 812 82 66 00</A> eller via mejl
        till <A href="mailto:support@collabodoc.com">support@collabodoc.com</A>{" "}
        där du beskriver felet och när det inträffade (vad du gjorde eller
        försökte göra när felet uppstod).
      </P>
    </Container>
  );
};

export default UnexpectedErrorView;
