import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import {InputLabel} from "../Typography";
import {Input} from "./Input";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";

const SearchBar = styled(Input).attrs(() => ({
    type: "search",
}))`
  display: inline;
  font-size: 16px;
  padding-left: 2rem;
  &:focus {
    box-shadow: none;
  }
  
  ${({ whiteSearchBar }) => whiteSearchBar && `
    background-color: white;
    border-color: ${({ theme }) => theme.input.border.color};

  &:focus {
    background-color: white;
    border-color: ${({ theme }) => theme.input.focus.border.color};
  }
  `}
`;

const IconWrapper = styled.span`
  position: absolute;
  left: 8px;
  top: 6px;
  display: inline;
  z-index: 2;
  color: ${({theme}) => theme.colors.primary};
`;

const SearchParent = styled.div`
  position: relative;
`;

export const SearchBarWithLabel = ({
                                       id,
                                       label,
                                       value,
                                       placeholder,
                                       handleChange,
                                       whiteSearchBar
                                   }) => {
    return (
        <>
            {label && <InputLabel htmlFor={id}>{label}</InputLabel>}
            <SearchParent>
                <SearchBar
                    whiteSearchBar={whiteSearchBar}
                    id={id}
                    onChange={({target}) => handleChange(target.value)}
                    value={value}
                    placeholder={placeholder}
                />
                <IconWrapper>
                    <FontAwesomeIcon icon={faMagnifyingGlass}/>
                </IconWrapper>
            </SearchParent>
        </>
    );
};

SearchBarWithLabel.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    handleChange: PropTypes.func,
    whiteSearchBar: PropTypes.bool
};
