import { isIE } from "react-device-detect";

const COLORS = {
  primary: "#0a4a7c",
  secondary: "#A0F0EB",
  tertiary: "#D0F8F5",
  danger: "#e32424",
  disabled: "#f1f1f1",
  black: "#000000",
  white: "#ffffff",
  success: "#4FD142",
  focus: "#FFD750",
};

const defaultTheme = {
  fontFamily: "proximanova-regular, sans-serif",
  colors: COLORS,
  button: {
    radius: "30px",
    primary: {
      color: COLORS.primary,
      hover: "#09385c",
    },
    secondary: {
      color: COLORS.white,
      hover: "#e3faf8",
    },
    copy: {
      backgroundColor: COLORS.white,
      borderColor: COLORS.primary,
      hover: {
        backgroundColor: "#e3faf8",
      },
    },
    success: {
      color: COLORS.success,
      hover: "#4cc740",
      textColor: COLORS.white,
    },
    danger: {
      color: COLORS.danger,
      hover: "#c81e1e",
    },
  },
  horizontalLine: {
    color: COLORS.primary,
  },
  icon: {
    fill: COLORS.primary,
  },
  input: {
    backgroundColor: COLORS.tertiary,
    color: COLORS.primary,
    dangerColor: "#e32424",
    disabledColor: COLORS.disabled,
    disabledColorSupplement: "#e4e4e4",
    toggle: {
      inActiveColor: "#fefefe",
      inActiveBackgroundColor: "#dadada",
    },
    border: {
      width: "1px",
      radius: "0rem",
      color: "#A0F0EB",
    },
    focus: {
      backgroundColor: COLORS.tertiary,
      color: COLORS.black,
      border: {
        color: COLORS.primary,
      },
    },
    dropDown: {
      backgroundColor: COLORS.tertiary,
      listBackgroundColor: COLORS.white,
      color: COLORS.primary,
      marginTop: isIE ? "6px" : "43px",
      border: {
        width: "1px",
        color: COLORS.primary,
      },
      hover: {
        color: COLORS.primary,
        backgroundColor: COLORS.tertiary,
        border: COLORS.primary,
      },
    },
  },
  navbar: {
    color: COLORS.white,
    backgroundColor: COLORS.primary,
    border: {
      color: COLORS.primary,
      width: "1px",
    },
    logo: {
      height: "24px",
      marginTop: "-4px",
      marginBottom: "0px",
    },
    link: {
      hover: {
        color: COLORS.primary,
        backgroundColor: COLORS.secondary,
      },
    },
  },
  table: {
    row: {
      odd: {
        backgroundColor: COLORS.tertiary,
        color: COLORS.black,
      },
      even: {
        backgroundColor: COLORS.white,
        color: COLORS.black,
      },
      hover: {
        backgroundColor: COLORS.secondary,
        color: COLORS.black,
      },
    },
  },
  typography: {
    headerPrimaryColor: COLORS.primary,
    paragraphPrimaryColor: COLORS.black,
    fontSize: "16px",
    font: "proximanova-regular, sans-serif",
    boldFont: "proximanova-bold, sans-serif",
    headingFont: "proximanova-bold, sans-serif",
    headingFontWeight: "bold",
    linkColor: COLORS.primary,
  },
  checkbox: {
    border: {
      color: COLORS.primary,
    },
    check: {
      border: {
        color: COLORS.white,
      },
    },
    hover: {
      border: {
        color: COLORS.primary,
      },
    },
  },
  radioButton: {
    border: {
      color: COLORS.primary,
    },
    check: {
      border: {
        color: COLORS.white,
      },
    },
    hover: {
      border: {
        color: COLORS.primary,
      },
    },
  },
  spinner: {
    color: COLORS.primary,
  },
  feedback: {
    color: COLORS.white,
  },
};

export default defaultTheme;
