import PropTypes from "prop-types";
import styled from "styled-components";

export const PrimaryButton = styled.button`
  font-size: 16px;
  border-radius: ${({ theme }) => theme.button.radius};
  border: 1px solid ${({ theme }) => theme.button.primary.color};
  background-color: ${({ theme }) => theme.button.primary.color};
  color: white;
  padding: 0.375rem 0.75rem;

  &:hover {
    background-color: ${({ theme }) => theme.button.primary.hover};
    border: 1px solid ${({ theme }) => theme.button.primary.hover};
  }

  &:active {
    background-color: ${({ theme }) => theme.button.primary.hover};
    border: 1px solid ${({ theme }) => theme.button.primary.hover};
    box-shadow: inset 0 3px 5px rgb(0 0 0 / 40%);
    outline: none;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: 0.65;
    &:hover {
      background-color: ${({ theme }) => theme.button.primary.color};
      border: 1px solid ${({ theme }) => theme.button.primary.color};
    }

    &:active {
      box-shadow: none;
      outline: none;
    }
  }
`;

PrimaryButton.propTypes = {
  disabled: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export const SecondaryButton = styled(PrimaryButton)`
  background-color: ${({ theme }) => theme.button.secondary.color};
  color: ${({ theme }) => theme.button.primary.color};

  &:hover {
    background-color: ${({ theme }) => theme.button.secondary.hover};
    color: ${({ theme }) => theme.button.primary.color};
  }

  &:active {
    background-color: ${({ theme }) => theme.button.secondary.hover};
    border: 1px solid ${({ theme }) => theme.button.primary.color};
    box-shadow: inset 0 3px 5px rgb(0 0 0 / 13%);
  }

  &:disabled {
    &:hover {
      background-color: ${({ theme }) => theme.button.secondary.color};
      color: ${({ theme }) => theme.button.primary.color};
    }
  }
`;

SecondaryButton.propTypes = {
  disabled: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export const SuccessButton = styled(PrimaryButton)`
  background-color: ${({ theme }) => theme.button.success.color};
  color: ${({ theme }) => theme.button.success.textColor};
  border: 1px solid ${({ theme }) => theme.button.success.color};

  &:hover {
    background-color: ${({ theme }) => theme.button.success.hover};
    border: 1px solid ${({ theme }) => theme.button.success.hover};
  }

  &:active {
    background-color: ${({ theme }) => theme.button.success.hover};
    border: 1px solid ${({ theme }) => theme.button.success.hover};
    box-shadow: inset 0 3px 5px rgb(0 0 0 / 13%);
  }

  &:disabled {
    &:hover {
      background-color: ${({ theme }) => theme.button.success.color};
      border: 1px solid ${({ theme }) => theme.button.success.color};
    }
  }
`;

SuccessButton.propTypes = {
  disabled: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export const DangerButton = styled(PrimaryButton)`
  background-color: ${({ theme }) => theme.button.danger.color};
  border: 1px solid ${({ theme }) => theme.button.danger.hover};

  &:hover {
    background-color: ${({ theme }) => theme.button.danger.hover};
    border: 1px solid ${({ theme }) => theme.button.danger.hover};
  }

  &:active {
    background-color: ${({ theme }) => theme.button.danger.hover};
    border: 1px solid ${({ theme }) => theme.button.danger.hover};
    box-shadow: inset 0 3px 5px rgb(0 0 0 / 13%);
  }

  &:disabled {
    &:hover {
      background-color: ${({ theme }) => theme.button.danger.color};
      border: 1px solid ${({ theme }) => theme.button.danger.color};
    }
  }
`;

DangerButton.propTypes = {
  disabled: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export const TextButton = styled.button`
  text-decoration: none;
  border: none;
  background-color: transparent;
  color: ${({ theme }) => theme.typography.linkColor};
  &:hover {
    color: ${({ theme }) => theme.typography.linkColor};
    text-decoration: underline;
    border: none;
  }
  &:active,
  :focus {
    color: ${({ theme }) => theme.typography.linkColor};
    text-decoration: underline;
    border: none;
  }
`;

TextButton.propTypes = {
  disabled: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
