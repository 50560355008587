import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const StyledRadioButton = styled.span`
  &::before {
    position: absolute;
    content: "";
    top: 5px;
    left: 0;
    height: 15px;
    width: 15px;
    border: ${({ theme }) => theme.radioButton.border.color} 1px solid;
    border-radius: 50%;
  }

  &::after,
  :checked {
    position: absolute;
    top: 9px;
    left: 4px;
    height: 7px;
    width: 7px;
    border-radius: 50%;
    background: ${({ theme }) => theme.radioButton.check.border.color};
  }
`;

const HiddenRadioInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;

  &:checked + ${StyledRadioButton}::before {
    background: ${({ theme }) => theme.radioButton.border.color};
    border: ${({ theme }) => theme.radioButton.border.color} 1px solid;
  }

  &:checked + ${StyledRadioButton}::after {
    content: "";
  }

  &:not(:checked):hover + ${StyledRadioButton}::before {
    box-shadow: 0 0 0 0.75px
      ${({ theme }) => theme.radioButton.hover.border.color} inset;
    border-color: ${({ theme }) => theme.radioButton.hover.border.color};
  }
`;

export const Label = styled.label`
  font-size: 16px;
  display: block;
  position: relative;
  padding-top: 1px;
  padding-left: 20px;
  cursor: pointer;
  user-select: none;
`;


export const RadioButtonWithLabel = ({
  id,
  label,
  name,
  handleChange,
  checked,
}) => {
  const onChange = ({ target }) => {
    handleChange(target.value);
  };
  return (
    <Label>
      {" "}
      {label}
      <HiddenRadioInput
        id={id}
        type="radio"
        name={name}
        checked={checked}
        onChange={onChange}
      />
      <StyledRadioButton />
    </Label>
  );
};

RadioButtonWithLabel.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
};
