import defaultTheme from "./defaultTheme";
import { merge, cloneDeep } from "lodash";

const COLORS = {
  primary: "#0075b0",
  secondary: "#00A0C8",
  tertiary: "#E78300",
  danger: "#F54359",
  focus: "#E78300",
  focusLight: "#e6f1f7",
  white: "#FFFFFF",
  black: "#000000",
  success: "#b6bf02",
};

const ptjTheme = {
  fontFamily: "Source Sans Pro",
  colors: COLORS,
  button: {
    primary: {
      color: COLORS.primary,
      hover: "#ed9a2d",
    },
    secondary: {
      hover: COLORS.focusLight,
    },
    radius: "5px",
    copy: {
      backgroundColor: COLORS.white,
      borderColor: COLORS.primary,
      hover: {
        backgroundColor: COLORS.focusLight,
      },
    },
    danger: {
      color: COLORS.danger,
      hover: "#d92a3f",
    },
  },
  chat: {
    senderBackground: COLORS.secondary,
  },
  horizontalLine: {
    color: COLORS.primary,
  },
  icon: {
    fill: COLORS.primary,
  },
  input: {
    backgroundColor: COLORS.white,
    color: COLORS.primary,
    dangerColor: "#aa2b23",
    border: {
      color: COLORS.primary,
    },
    focus: {
      backgroundColor: COLORS.focusLight,
      border: {
        color: COLORS.tertiary,
        boxShadow: "0 0 0 0.2rem rgba(170, 43, 35,.25)",
      },
    },
    dropDown: {
      backgroundColor: COLORS.white,
      listBackgroundColor: COLORS.white,
      color: COLORS.primary,
      border: {
        width: "1px",
        color: COLORS.primary,
      },
      hover: {
        color: COLORS.white,
        backgroundColor: COLORS.secondary,
        border: COLORS.primary,
      },
    },
  },
  navbar: {
    color: COLORS.white,
    backgroundColor: COLORS.secondary,
    border: {
      color: COLORS.primary,
    },
    logo: {
      height: "36px",
      marginTop: "-8px",
    },
    link: {
      hover: {
        color: COLORS.white,
        backgroundColor: COLORS.tertiary,
      },
    },
  },
  typography: {
    headerPrimaryColor: COLORS.black,
    font: "Source Sans Pro",
    boldFont: "Source Sans Pro Bold",
    headingFont: "Source Sans Pro Bold",
    linkColor: COLORS.black,
  },
  checkbox: {
    border: {
      color: COLORS.primary,
    },
    check: {
      border: {
        color: COLORS.white,
      },
    },
    hover: {
      border: {
        color: COLORS.primary,
      },
    },
  },
  radioButton: {
    border: {
      color: COLORS.primary,
    },
    check: {
      border: {
        color: COLORS.white,
      },
    },
    hover: {
      border: {
        color: COLORS.primary,
      },
    },
  },
  spinner: {
    color: COLORS.primary,
  },
  feedback: {
    color: COLORS.white,
  },
  table: {
    row: {
      odd: {
        backgroundColor: "#f3f3f3",
        color: COLORS.black,
      },
      even: {
        backgroundColor: COLORS.white,
        color: COLORS.black,
      },
      hover: {
        backgroundColor: COLORS.secondary,
        color: COLORS.white,
      },
    },
  },
};

export default merge(cloneDeep(defaultTheme), ptjTheme);
