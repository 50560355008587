import defaultTheme from "./defaultTheme";
import { merge, cloneDeep } from "lodash";

const COLORS = {
  primary: "#004e3d",
  secondary: "#132420",
  tertiary: "#cdf4c6",
  focus: "#5f365b",
  focusLight: "#b4accd",
  white: "#FFFFFF",
  black: "#000000",
  disabled: "#F3F3F3",
  success: "#009063",
  danger: "#ee7947"
};

const doktorseTheme = {
  fontFamily: "Helvetica Now Regular",
  colors: COLORS,
  button: {
    primary: {
      color: COLORS.primary,
      hover: COLORS.secondary,
    },
    secondary: {
      hover: COLORS.tertiary,
    },
    success:{
      color: COLORS.success,
      textColor: COLORS.white,
      hover: COLORS.success
    },
    danger:{
      color: COLORS.danger,
      hover: COLORS.danger
    },
    copy: {
      backgroundColor: COLORS.white,
      borderColor: COLORS.primary,
      hover: {
        backgroundColor: COLORS.tertiary,
      },
    },
  },
  horizontalLine: {
    color: COLORS.primary,
  },
  icon: {
    fill: COLORS.focus,
  },
  input: {
    backgroundColor: COLORS.white,
    color: COLORS.primary,
    dangerColor: "#ee7947",
    border: {
      color: COLORS.primary,
    },
    focus: {
      backgroundColor: "#f0fcee",
      border: {
        color: COLORS.secondary,
        boxShadow: "0 0 0 0.2rem rgba(170, 43, 35,.25)",
      },
    },
    dropDown: {
      backgroundColor: COLORS.white,
      listBackgroundColor: COLORS.white,
      color: COLORS.secondary,
      border: {
        width: "1px",
        color: COLORS.secondary,
      },
      hover: {
        color: COLORS.secondary,
        backgroundColor: "#f0fcee",
        border: COLORS.secondary,
      },
    },
  },
  navbar: {
    color: COLORS.primary,
    backgroundColor: "#fff9f3",
    border: {
      color: "#fff9f3",
      width: "3px",
    },
    logo: {
      height: "32px",
      marginTop: "-6px",
    },
    link: {
      hover: {
        color: COLORS.secondary,
        backgroundColor: "#f0fcee",
      },
    },
  },
  typography: {
    headerPrimaryColor: COLORS.primary,
    fontSize: "14px",
    font: "Helvetica Now Light",
    boldFont: "Helvetica Now Regular",
    headingFont: "Helvetica Now Regular",
    linkColor: COLORS.primary,
  },
  checkbox: {
    border: {
      color: COLORS.secondary,
    },
    check: {
      border: {
        color: COLORS.white,
      },
    },
    hover: {
      border: {
        color: COLORS.tertiary,
      },
    },
  },
  radioButton: {
    border: {
      color: COLORS.primary,
    },
    check: {
      border: {
        color: COLORS.white,
      },
    },
    hover: {
      border: {
        color: COLORS.primary,
      },
    },
  },
  spinner: {
    color: COLORS.primary,
  },
  feedback: {
    color: COLORS.white,
  },
  table: {
    row: {
      odd: {
        backgroundColor: "#F3F3F3",
        color: COLORS.secondary,
      },
      even: {
        backgroundColor: COLORS.white,
        color: COLORS.secondary,
      },
      hover: {
        backgroundColor: COLORS.tertiary,
        color: COLORS.primary,
      },
    },
  },
};

export default merge(cloneDeep(defaultTheme), doktorseTheme);
