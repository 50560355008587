import PropTypes from "prop-types";
import React, { forwardRef } from "react";
import styled, { keyframes } from "styled-components";
import { InputLabel } from "../Typography";
import { ValidationError } from "./Input";
import TextareaAutosize from "react-textarea-autosize";

export const TextArea = styled(TextareaAutosize)`
  font-size: 16px;
  background-color: ${({ theme }) => theme.input.backgroundColor};
  color: ${({ theme }) => theme.input.color};
  border-width: ${({ theme }) => theme.input.border.width};
  border-style: solid;
  border-color: ${({ theme }) => theme.input.border.color};
  border-radius: ${({ theme }) => theme.input.border.radius};
  width: 100%;
  padding: 0.375rem 0.75rem;
  resize: ${(props) => (props.$resizeHandles ? "both" : "none")};

  &:focus {
    color: ${({ theme }) => theme.input.focus.color};
    background-color: ${({ theme }) => theme.input.focus.backgroundColor};
    border-color: ${({ theme }) => theme.input.focus.border.color};
    outline: 0;
  }

  &:hover {
    cursor: text;
  }
`;

export const WhiteTextArea = styled(TextArea)`
  background-color: white;
  border-color: ${({ theme }) => theme.input.focus.border.color};

  &:focus {
    background-color: white;
    border-color: ${({ theme }) => theme.input.focus.border.color};
  }
`;

const CharacterCounter = styled.span`
  float: right;
  font-size: 80%;
  margin-right: 0.5rem;
  animation-name: ${({ isMax }) => isMax && shake};
  animation-duration: 100ms;
  animation-timing-function: linear;
  animation-iteration-count: 3;
`;
const shake = keyframes`
  0% {
    transform: translate(0%, 0);
  }
  25% {
    transform: translate(-0.5rem, 0);
  }
  50% {
    transform: translate(0, 0);
  }
  75% {
    transform: translate(0.5rem, 0);
  }
  100% {
    transform: translate(0, 0);
  }
`;

export const TextAreaWithLabelAndError = forwardRef(
  (
    {
      autoFocus,
      handleChange,
      handleBlur,
      label,
      required,
      id,
      placeholder,
      validationError,
      value,
      minRows,
      maxRows,
      whiteTextArea,
      disabled,
      maxLength,
      resizeHandles,
    },
    ref
  ) => {
    value = value || "";
    const truncatedValue = maxLength ? value.slice(0, maxLength) : value;
    const characterCount = truncatedValue.length;
    return (
      <>
        {label && (
          <InputLabel isRequired={required} htmlFor={id}>
            {label}
          </InputLabel>
        )}
        {whiteTextArea ? (
          <WhiteTextArea
            autoFocus={autoFocus}
            minRows={minRows}
            maxRows={maxRows}
            id={id}
            placeholder={placeholder}
            value={truncatedValue}
            required={required}
            ref={ref}
            onBlur={handleBlur}
            onChange={({ target }) => handleChange(target.value)}
            disabled={disabled}
            maxLength={maxLength}
            $resizeHandles={resizeHandles}
          />
        ) : (
          <TextArea
            autoFocus={autoFocus}
            minRows={minRows}
            maxRows={maxRows}
            id={id}
            onBlur={handleBlur}
            onChange={({ target }) => handleChange(target.value)}
            placeholder={placeholder}
            value={truncatedValue}
            required={required}
            ref={ref}
            disabled={disabled}
            maxLength={maxLength}
            $resizeHandles={resizeHandles}
          />
        )}
        <CharacterCounter isMax={characterCount === maxLength}>
          {maxLength && `${characterCount} / ${maxLength}`}
        </CharacterCounter>
        <ValidationError>{validationError && validationError}</ValidationError>
      </>
    );
  }
);

TextAreaWithLabelAndError.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  validationError: PropTypes.string,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  autoFocus: PropTypes.bool,
  minRows: PropTypes.number,
  maxRows: PropTypes.number,
  whiteTextArea: PropTypes.bool,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
  resizeHandles: PropTypes.bool,
};

TextAreaWithLabelAndError.defaultProps = {
  whiteTextArea: false,
  resizeHandles: true,
};
