﻿import React from 'react';
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";


const AnonymizationInfoFooter = ({tabName, numberOfDays}) => {

    return (
        <InfoFooter>
            <FontAwesomeIcon className="" icon={faInfoCircle}/>&nbsp;
            Patienter i denna flik ({tabName}) tas bort efter {numberOfDays} dag(ar).
        </InfoFooter>
    )

}

const InfoFooter = styled.div`
    position: fixed;
    bottom: 0;
    z-index: -1;
    opacity: 0.8;
`

AnonymizationInfoFooter.propTypes = {
    tabName: PropTypes.string,
    numberOfDays: PropTypes.number
};

export default AnonymizationInfoFooter;