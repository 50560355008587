import defaultTheme from "./defaultTheme";
import { merge, cloneDeep } from "lodash";

const COLORS = {
  primary: "#0074b9",
  secondary: "#000",
  tertiary: "#f0f4fa",
  focus: "#c64187",
  focusLight: "#d66aa8",
  white: "#FFFFFF",
  black: "#000000",
  disabled: "#f1f1f1",
  success: "#74a84d",
  danger: "#c64187",
};

const wetterhalsanTheme = {
  fontFamily: "Open Sans",
  colors: COLORS,
  button: {
    primary: {
      color: COLORS.primary,
      hover: "#358ed1",
    },
    secondary: {
      hover: COLORS.tertiary,
    },
    radius: "0.3rem",
    copy: {
      backgroundColor: COLORS.white,
      borderColor: COLORS.primary,
      hover: {
        backgroundColor: COLORS.tertiary,
      },
    },
    success: {
      color: COLORS.success,
      hover: "#88b556",
    },
    danger: {
      color: COLORS.danger,
      hover: "#d66aa8",
    },
  },
  horizontalLine: {
    color: COLORS.primary,
  },
  icon: {
    fill: COLORS.primary,
  },
  input: {
    backgroundColor: COLORS.white,
    color: COLORS.black,
    dangerColor: COLORS.focus,
    border: {
      color: COLORS.black,
    },
    focus: {
      backgroundColor: COLORS.white,
      border: {
        color: COLORS.focus,
        boxShadow: "0 0 0 0.2rem rgba(170, 43, 35,.25)",
      },
    },
    dropDown: {
      backgroundColor: COLORS.white,
      listBackgroundColor: COLORS.white,
      color: "#f2d7e7",
      border: {
        width: "1px",
        color: COLORS.black,
      },
      hover: {
        color: COLORS.focus,
        backgroundColor: "#f2d7e7",
        border: COLORS.black,
      },
    },
  },
  navbar: {
    color: COLORS.black,
    backgroundColor: COLORS.white,
    border: {
      color: COLORS.white,
      width: "3px",
    },
    logo: {
      height: "35px",
      marginTop: "-12px",
      marginBottom: "-10px",
    },
    link: {
      hover: {
        color: COLORS.focus,
        backgroundColor: "#f2d7e7",
      },
    },
  },
  typography: {
    headerPrimaryColor: COLORS.primary,
    fontSize: "14px",
    font: "Open Sans",
    boldFont: "Open Sans",
    headingFont: "Merriweather Bold",
    headingFontWeight: "bold",
    linkColor: COLORS.primary,
  },
  checkbox: {
    border: {
      color: COLORS.primary,
    },
    check: {
      border: {
        color: COLORS.white,
      },
    },
    hover: {
      border: {
        color: COLORS.primary,
      },
    },
  },
  radioButton: {
    border: {
      color: COLORS.primary,
    },
    check: {
      border: {
        color: COLORS.white,
      },
    },
    hover: {
      border: {
        color: COLORS.primary,
      },
    },
  },
  spinner: {
    color: COLORS.focus,
  },
  feedback: {
    color: COLORS.white,
  },
  table: {
    row: {
      odd: {
        backgroundColor: "#f3f3f2",
        color: COLORS.black,
      },
      even: {
        backgroundColor: COLORS.white,
        color: COLORS.black,
      },
      hover: {
        backgroundColor: "#e1e1e1",
        color: COLORS.black,
      },
    },
  },
};

export default merge(cloneDeep(defaultTheme), wetterhalsanTheme);
