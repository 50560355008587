import { useCallback, useEffect, useState } from "react";
import { doFetch } from "./fetchUtils";

/**
 *
 * @param {string | URL | RequestInfo} input
 * @param {RequestInit || {}} init
 * @return {{data: *, doFetch: (function(input?: string | URL | RequestInfo, init?: RequestInit): Promise<{data: *, error: *, response: Response}>), error: *, isLoading: boolean, response: Response}}
 */
const useFetchy = (input = "", init = {}) => {
  const fetchImmediately = Boolean(
    input && (!init.method || init.method === "GET")
  );
  const [isLoading, setIsLoading] = useState(fetchImmediately);
  const [response, setResponse] = useState();
  const [data, setData] = useState();
  const [error, setError] = useState();

  const fetchCallback = useCallback(
    (...args) => {
      setIsLoading(true);
      setResponse(undefined);
      return doFetch(args[0] || input, args[1] || init)
        .then((props) => {
          setData(props.data);
          setError(props.error);
          setResponse(props.response);
          return props;
        })
        .catch((reason) => {
          console.info(reason.message);
          setIsLoading(false);
          return {};
        });
    },
    [input, JSON.stringify(init), doFetch]
  );

  useEffect(() => {
    const abortController = new AbortController();
    if (fetchImmediately) {
      init.signal = abortController.signal;
      fetchCallback(input, init);
    }
    return () => {
      abortController.abort();
    };
  }, [fetchImmediately, input, JSON.stringify(init), doFetch]);

  useEffect(() => {
    response && setIsLoading(false);
  }, [response]);

  return {
    doFetch: fetchCallback,
    data,
    error,
    isLoading,
    response,
  };
};

export default useFetchy;
