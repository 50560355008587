import defaultTheme from "./defaultTheme";
import { merge, cloneDeep } from "lodash";

const COLORS = {
  primary: "#007864",
  secondary: "#006050",
  tertiary: "#339383",
  focus: "#FFAC99",
  focusLight: "#ffbdad",
  white: "#FFFFFF",
  black: "#000000",
  disabled: "#f1f1f1",
  success: "#6FC993",
  danger: "#AA112C",
};

const melivaTheme = {
  fontFamily: "Montserrat",
  colors: COLORS,
  button: {
    primary: {
      color: COLORS.primary,
      hover: "#006050",
    },
    secondary: {
      hover: "#e0efec",
    },
    copy: {
      backgroundColor: COLORS.white,
      borderColor: COLORS.primary,
      hover: {
        backgroundColor: "#e0efec",
      },
    },
    success: {
      color: COLORS.success,
      hover: "#64b584",
      textColor: COLORS.black,
    },
    danger: {
      color: COLORS.danger,
      hover: "#880e23",
    },
  },
  horizontalLine: {
    color: COLORS.primary,
  },
  icon: {
    fill: COLORS.primary,
  },
  input: {
    backgroundColor: COLORS.white,
    color: COLORS.primary,
    dangerColor: COLORS.danger,
    border: {
      color: "#339383",
    },
    focus: {
      backgroundColor: "#e0efec",
      border: {
        color: COLORS.primary,
        boxShadow: "0 0 0 0.2rem rgba(170, 43, 35,.25)",
      },
    },
    dropDown: {
      backgroundColor: COLORS.white,
      listBackgroundColor: COLORS.white,
      color: COLORS.primary,
      border: {
        width: "1px",
        color: COLORS.primary,
      },
      hover: {
        color: COLORS.primary,
        backgroundColor: "#e0efec",
        border: COLORS.primary,
      },
    },
  },
  navbar: {
    color: COLORS.tertiary,
    backgroundColor: COLORS.white,
    border: {
      color: "#A6A6A6",
      width: "1px",
    },
    logo: {
      height: "40px",
      marginTop: "-10px",
      marginBottom: "-5px",
    },
    link: {
      hover: {
        color: COLORS.primary,
        backgroundColor: COLORS.white,
      },
    },
  },
  typography: {
    headerPrimaryColor: COLORS.primary,
    fontSize: "14px",
    font: "Montserrat",
    boldFont: "Montserrat",
    headingFont: "Montserrat Semibold",
    headingFontWeight: "normal",
    linkColor: COLORS.primary,
  },
  checkbox: {
    border: {
      color: COLORS.primary,
    },
    check: {
      border: {
        color: COLORS.white,
      },
    },
    hover: {
      border: {
        color: COLORS.primary,
      },
    },
  },
  radioButton: {
    border: {
      color: COLORS.primary,
    },
    check: {
      border: {
        color: COLORS.white,
      },
    },
    hover: {
      border: {
        color: COLORS.primary,
      },
    },
  },
  spinner: {
    color: COLORS.primary,
  },
  feedback: {
    color: COLORS.white,
  },
  table: {
    row: {
      odd: {
        backgroundColor: "#cbe7df",
        color: COLORS.black,
      },
      even: {
        backgroundColor: COLORS.white,
        color: COLORS.black,
      },
      hover: {
        backgroundColor: "#c2dfda",
        color: COLORS.black,
      },
    },
  },
};

export default merge(cloneDeep(defaultTheme), melivaTheme);
