import PropTypes from "prop-types";
import styled from "styled-components";
import React, {useEffect, useState} from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons";
import {isIE} from "react-device-detect";

const DropDownContainer = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  pointer-events: ${(props) => (props.isDisabled && 'none')};
  filter: ${(props) => (props.isDisabled && 'grayscale(1)')};
`;

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const SelectDiv = styled.div`
  width: 100%;
  overflow: hidden;
  padding: 0.375rem 0.75rem;
  background-color: ${({theme}) => theme.input.dropDown.backgroundColor};
  background-color: ${({theme}) => theme.input.dropDown.backgroundColor};
  border-width: ${({theme}) => theme.input.dropDown.border.width};
  border-style: solid;
  border-color: ${({theme}) => theme.input.dropDown.border.color};
  border-radius: ${({theme}) => theme.input.dropDown.border.radius};
  font-size: 1rem;
  background-color: ${({theme}) => theme.input.dropDown.backgroundColor};
  color: ${({theme}) => theme.input.dropDown.border.color};
`;

const ChevronDiv = styled.div`
  width: 40px;
  background-color: ${({theme}) => theme.input.dropDown.backgroundColor};
  background-color: ${({theme}) => theme.input.dropDown.backgroundColor};
  border-width: ${({theme}) => theme.input.dropDown.border.width};
  border-style: solid solid solid none;
  border-color: ${({theme}) => theme.input.dropDown.border.color};
  border-radius: ${({theme}) => theme.input.dropDown.border.radius};
  color: ${({theme}) => theme.input.dropDown.border.color};
  padding-top: 6px;
  text-align: center;
`;

const MenuWrapper = styled.div`
  width: 100%;
  position: absolute;
  z-index: 2;
  max-height: 75vh;
  overflow-y: auto;
  padding: 5px 0;
  font-size: 1rem;
  margin-top: ${({theme}) => theme.input.dropDown.marginTop};
  background-color: ${({theme}) => theme.input.dropDown.listBackgroundColor};
  border-width: ${({theme}) => theme.input.dropDown.border.width};
  border-style: solid;
  border-color: ${({theme}) => theme.input.dropDown.border.color};
  box-shadow: none;
`;

const Option = styled.li`
  list-style: none;
  padding: 0.375rem 0.75rem;
  transition: background 0.2s ease-in-out;
  cursor: pointer;
  &:hover {
    background-color: ${({theme}) =>
    theme.input.dropDown.hover.backgroundColor};
  }
`;

export const DropDown = ({
                           placeholder,
                           selected,
                           options,
                           handler,
                           sort = false,
                           isDisabled,
                         }) => {
  if (sort) {
    options.sort((a, b) => {
      const textA = a.text.toUpperCase();
      const textB = b.text.toUpperCase();
      if (textA < textB) {
        return -1;
      }
      if (textA > textB) {
        return 1;
      }
      return 0;
    });
  }

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setSelectedOption(selected)
  }, [selected])

  const ref = useOnclickOutside(() => {
    setIsOpen(false);
  });

  const handleClick = (id, text) => {
    setIsOpen(true);
    setSelectedOption(text);
    handler(id, text);
  };

  return (
      <DropDownContainer onClick={toggleMenu} ref={ref} isDisabled={isDisabled}>
        <SelectWrapper>
          <SelectDiv>{selectedOption || placeholder}</SelectDiv>
          <ChevronDiv>
            <FontAwesomeIcon icon={faChevronDown}/>
          </ChevronDiv>
        </SelectWrapper>
        {isOpen && (
            <MenuWrapper>
              {options.map(({text, id}) => (
                  <Option key={id} onClick={() => handleClick(id, text)}>
                    {text}
                  </Option>
              ))}
            </MenuWrapper>
        )}
      </DropDownContainer>
  );
};

DropDown.propTypes = {
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  options: PropTypes.array,
  handler: PropTypes.func,
  sort: PropTypes.bool,
  selected: PropTypes.string,
  isDisabled: PropTypes.bool
};
