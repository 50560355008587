/**
 * @param {Object} props
 * @param {string || undefined} props.accessToken
 * @param {string || undefined} props.method
 * @param {*} props.data
 * @return RequestInit
 */
export const getRequestInit = ({ accessToken, method, data } = {}) => {
  const init = { headers: {} };
  if (accessToken) {
    init.headers.Authorization = `Bearer ${accessToken}`;
  }
  if (method) {
    init.method = method;
  }
  if (data) {
    init.body = JSON.stringify(data);
    init.headers["Content-Type"] = "application/json";
  }
  init.headers.Accept = "application/json";
  return init;
};

/**
 *
 * @param {Response} response
 * @return {Promise<*>}
 */
export const getResponseContent = async (response) => {
  const contentType = response.headers.get("content-type");
  if (!contentType) {
    return;
  }
  if (contentType.includes("text/plain")) {
    return await response.text();
  }
  if (contentType.includes("application/json")) {
    return await response.json();
  }
  if (contentType.includes("application/problem+json")) {
    return await response.json();
  }
  if (contentType.includes("application/pdf")) {
    return await response.blob();
  }
  if (contentType.includes("application/octet-stream")) {
    return await response.blob();
  }
  if (contentType.includes("image/")) {
    return await response.blob();
  }
  console.warn(`Implementation missing for ${contentType}`);
  return await response.text();
};

/**
 * Makes a request using the {@link https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch|Fetch API}
 * @param {string | URL | RequestInfo} input
 * @param {RequestInit || {}} init
 * @return {Promise<{data: *, error: *, response: Response}>}
 */
export const doFetch = async (input = "", init = {}) => {
  return await window.fetch(input, init).then(async (response) => {
    let data;
    let error;
    const content = await getResponseContent(response);
    if (response.ok) {
      data = content;
      error = undefined;
    } else {
      data = undefined;
      error = content;
    }
    return {
      response,
      data,
      error,
    };
  });
};
