﻿import {useEffect, useState} from "react";


const semver = require('semver');


const initialState = {
    loading: true,
    isLatestVersion: false
};

const CacheBuster = ({currentVersion,children}) => {
    const [state, setState] = useState(initialState);

    useEffect(() => {
        fetch('/meta.json')
            .then((response) => response.json())
            .then((meta) => {
                const latestVersion = meta.version;
                const shouldForceRefresh = semver.gt(latestVersion, currentVersion);

                if (shouldForceRefresh) {
                    console.log(`New version available - ${latestVersion}.`);
                    setState({loading: false, isLatestVersion: false});
                } else {
                    console.log(`Already the latest version - ${latestVersion}. No cache refresh needed.`);
                    setState({loading: false, isLatestVersion: true});
                }
            });
    },[]);

    const refreshCacheAndReload = () => {
        if(caches){
            caches.keys().then((names) => {
                for (let name of names) caches.delete(name);
            })
        }
        window.location.replace(window.location.href);
    }


    const { loading, isLatestVersion } = state;
    return children({loading, isLatestVersion, refreshCacheAndReload})
}

export default CacheBuster;