import React from "react";
import PropTypes from "prop-types";
import styled, {useTheme} from "styled-components";
import Clipboard from "react-clipboard.js";
import Notifications, {notify} from "react-notify-toast";
import {faCopy} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const CopyBtn = styled(Clipboard)`
  font-size: 16px;
  border-radius: ${({theme}) => theme.button.radius};
  border: 1px solid ${({theme}) => theme.button.copy.borderColor};
  background-color: ${({theme}) => theme.button.copy.backgroundColor};
  color: ${({theme}) => theme.colors.primary};

  &:hover {
    background-color: ${({theme}) => theme.button.copy.hover.backgroundColor};
  }

  &:active {
    background-color: ${({theme}) => theme.button.copy.hover.backgroundColor};
    border: 1px solid ${({theme}) => theme.button.copy.borderColor};
    box-shadow: inset 0 3px 5px rgb(0 0 0 / 13%);
    outline: none;
  }
`;

const BigCopyBtn = styled(CopyBtn)`
  padding: 0.375rem 0.75rem;
`;

const SmallCopyBtn = styled(CopyBtn)`
  padding: 0.15rem 0.45rem;
`;

const Icon = styled(FontAwesomeIcon)`
  margin-top: 3px;
  margin-right: 5px;
  float: left;
`;

const feedbackTimeout = 2000;

export const CopyButtonBig = ({
                                  id,
                                  buttonText,
                                  getText,
                                  textValue,
                                  feedback,
                              }) => {
    const colorTheme = useTheme();
    const onClick = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (feedback) {
            notify.show(feedback, "custom", feedbackTimeout, {
                background: colorTheme.colors.success,
                text: colorTheme.feedback.color,
            });
        }
    };

    return (
        <>
            <BigCopyBtn
                id={id}
                onClick={onClick}
                option-text={getText}
                data-clipboard-text={textValue}
            >
                <Icon icon={faCopy}/>
                {buttonText}
            </BigCopyBtn>
            <Notifications/>
        </>
    );
};

CopyButtonBig.propTypes = {
    id: PropTypes.string,
    buttonText: PropTypes.string,
    getText: PropTypes.func,
    textValue: PropTypes.string,
    feedback: PropTypes.string,
};

export const CopyButtonSmall = ({id, getText, textValue, feedback}) => {
    const colorTheme = useTheme();
    const onClick = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (feedback) {
            notify.show(feedback, "custom", feedbackTimeout, {
                background: colorTheme.colors.success,
                text: colorTheme.feedback.color,
            });
        }
    };

    return (
        <>
            <SmallCopyBtn
                id={id}
                onClick={onClick}
                option-text={getText}
                data-clipboard-text={textValue}
            >
                <FontAwesomeIcon icon={faCopy}/>
            </SmallCopyBtn>
            <Notifications/>
        </>
    );
};

CopyButtonSmall.propTypes = {
    id: PropTypes.string,
    getText: PropTypes.func,
    textValue: PropTypes.string,
    feedback: PropTypes.string,
};
