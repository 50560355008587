import React, { useEffect, useState } from 'react'
import PropTypes from "prop-types";
import styled from "styled-components";
import { InputLabel } from "../Typography";

const ToggleBackground = styled.div`
  cursor: ${({disabled}) => !disabled && "pointer"};
  display: inline-block;
  vertical-align: text-top;
  margin-left: 1rem;
  margin-top: 0.2rem;
  height: 1rem;
  width: 2.5rem;
  border-radius: 1rem;
  transition: all 200ms ease-in-out;
  box-shadow: 0 0 0 1px ${({theme}) => theme.input.color};  
  background-color: ${({ theme, isChecked, disabled }) => {
    if (disabled) return theme.input.disabledColor;
    if (isChecked) return theme.input.backgroundColor;
    return theme.input.toggle.inActiveBackgroundColor;
  }};
`;

const ToggleSwitch = styled.div`
  height: 1.4rem;
  width: 1.4rem;
  border-radius: 1.4rem;
  box-shadow: ${({disabled, hovered, theme}) => {
    if(!disabled && hovered) return `0 0 0 2px ${theme.input.color}`;
    return `0 0 0 1px ${theme.input.color}`;
  }};
  margin-left: -0.2rem;
  margin-top: -0.2rem;
  position: absolute;
  background-color: ${({ theme, disabled, isChecked }) =>
    !disabled && isChecked ? theme.input.color : theme.input.toggle.inActiveColor};
  transition: all 200ms ease-in-out;
  transform: translateX(${({ isChecked }) => (isChecked ? "1.5rem" : "0px")});
`;

const Toggle = ({ id, label, isOn = false, disabled = false, handleChange }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <>
      <InputLabel
        htmlFor={id}
        onClick={() => !disabled && handleChange()}
      >
        {label}
      </InputLabel>
      <ToggleBackground
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        isChecked={isOn}
        disabled={disabled}
        onClick={() => !disabled && handleChange()}
      >
        <ToggleSwitch isChecked={isOn} disabled={disabled} hovered={isHovered}/>
      </ToggleBackground>
      <input
        id={id}
        type={"checkbox"}
        disabled={disabled}
        defaultChecked={isOn}
        hidden
      />
    </>
  );
};

Toggle.propTypes = {
  id: PropTypes.string,
  isOn: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  handleChange: PropTypes.func
};

export default Toggle;
