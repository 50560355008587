import styled from "styled-components";
import { Table as BootstrapTable } from "react-bootstrap";

const Table = styled(BootstrapTable).attrs(() => ({
  striped: true,
  hover: true,
}))``;

const TableBodyRow = styled.tr`
  height: 3rem;
  cursor: pointer;
  border: none;

  :nth-child(odd) {
    background-color: ${({ theme }) =>
      theme.table.row.odd.backgroundColor} !important;
    color: ${({ theme }) => theme.table.row.odd.color};
  }
  :nth-child(even) {
    background-color: ${({ theme }) =>
      theme.table.row.even.backgroundColor} !important;
    color: ${({ theme }) => theme.table.row.even.color};
  }

  :hover {
    background-color: ${({ theme }) =>
      theme.table.row.hover.backgroundColor} !important;
    color: ${({ theme }) => theme.table.row.hover.color} !important;
  }
`;

const Th = styled.th`
  padding: 6px;
  border: none !important;
  text-align: ${({ alignment }) => alignment || "left"};
`;

const Td = styled.td`
  padding: 6px;
  border: none !important;
  text-align: ${({ alignment }) => alignment || "left"};
`;

export { Table, TableBodyRow, Th, Td };
