import PropTypes from "prop-types";
import styled from "styled-components";

export const H1 = styled.h1`
  color: ${({ theme }) => theme.typography.headerPrimaryColor};
  font-weight: ${({ theme }) => theme.typography.headingFontWeight};
  font-family: ${({ theme }) => theme.typography.headingFont};
  font-size: 2em;
`;

export const H2 = styled.h2`
  color: ${({ theme }) => theme.typography.headerPrimaryColor};
  font-weight: ${({ theme }) => theme.typography.headingFontWeight};
  font-family: ${({ theme }) => theme.typography.headingFont};
  font-size: 1.5em;
`;

export const H3 = styled.h3`
  color: ${({ theme }) => theme.typography.headerPrimaryColor};
  font-weight: ${({ theme }) => theme.typography.headingFontWeight};
  font-family: ${({ theme }) => theme.typography.headingFont};
  font-size: 1.17em;
`;

export const H4 = styled.h4`
  color: ${({ theme }) => theme.typography.headerPrimaryColor};
  font-weight: ${({ theme }) => theme.typography.headingFontWeight};
  font-family: ${({ theme }) => theme.typography.headingFont};
  font-size: 1em;
`;

export const H5 = styled.h5`
  color: ${({ theme }) => theme.typography.headerPrimaryColor};
  font-weight: ${({ theme }) => theme.typography.headingFontWeight};
  font-family: ${({ theme }) => theme.typography.headingFont};
  font-size: 0.83em;
`;

export const H6 = styled.h6`
  color: ${({ theme }) => theme.typography.headerPrimaryColor};
  font-weight: ${({ theme }) => theme.typography.headingFontWeight};
  font-family: ${({ theme }) => theme.typography.headingFont};
  font-size: 0.67em;
`;

export const P = styled.p`
  color: ${({ theme }) => theme.typography.paragraphPrimaryColor};
  font-size: ${({ theme }) => theme.typography.fontSize};
  font-weight: normal;
  font-family: ${({ theme }) => theme.typography.font};
`;

export const B = styled.p`
  color: ${({ theme }) => theme.typography.paragraphPrimaryColor};
  font-size: ${({ theme }) => theme.typography.fontSize};
  font-family: ${({ theme }) => theme.typography.boldFont};
  font-weight: bold;
`;

export const A = styled.a`
  text-decoration: none;
  font-size: ${({ theme }) => theme.typography.fontSize};
  color: ${({ theme }) => theme.typography.linkColor};
  &:hover {
    color: ${({ theme }) => theme.typography.linkColor};
  }
`;

export const InputLabel = styled.label`
  user-select: none;
  font-size: ${({ theme }) => theme.typography.fontSize};
  font-weight: bold;
  margin-right: 5px;
  &:after {
    content: "${({ isRequired }) => isRequired && " *"}";
    font-weight: bold;
    color: ${({ theme }) => theme.input.dangerColor};
  }
`;
InputLabel.propTypes = {
  required: PropTypes.bool,
};
